import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledWhatDoWeDo = styled.div`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(72)};
  padding-bottom: ${Rem(18)};
  margin-bottom: ${Rem(50)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(48)};
    margin-bottom: ${Rem(70)};
  }
`;

export const StyledWhatDoWeDoContent = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ${Rem(72)};
  }
`;

export const StyledWhatDoWeDoTitle = styled.h2`
  font-size: ${Rem(25)};
  line-height: ${Rem(35)};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    min-width: ${Rem(426)};
    max-width: ${Rem(426)};
    padding-right: ${Rem(60)};
  }
`;

export const StyledWhatDoWeDoColumns = styled.div`
  padding-bottom: ${Rem(5)};

  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const StyledWhatDoWeDoColumn = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - ${Rem(22)});
  }
`;

export const StyledWhatDoWeDoParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(24)};
`;
